@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300&display=swap');
:root {
  font-family: 'Comic Neue', cursive;
}
.carousel-caption {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  font-family: 'Comic Neue', cursive;
  padding: 15px;
  
  color: white;
  left: 0 !important;
  bottom: 5px;
  height: 400px;
  
 }
.title {
  text-align: center;
}
.d-block {
  width: auto;
  height: auto;
  max-width: 100%;

}
.container {
  font-family: 'Comic Neue', cursive;
  
}

.carousel-item {
  height: 600px;
  width: 100%;
  border-radius: 10px;
}

.active .carousel-item {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.08);
  
}

.carousel-inner {
  border-radius: 10px;
}

.project-title-desc {
  text-align: center;
}