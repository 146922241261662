.row {
    padding: 10px 20px;
    

    transition: all 0.3s ease-in-out;
}

.footer{
    border-radius: 10px;
    padding: 10px 20px;

    transition: all 0.3s ease-in-out;
}