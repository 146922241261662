@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300&display=swap');
.header {
    font-family: 'Comic Neue', cursive;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    

}