@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300&display=swap');
:root {
  --primary-color: rgb(47, 88, 75);
  --secondary-color: #282c34;
  --gradient-animation-duration: 10s;
  font-family: 'Comic Neue', cursive;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

main {
  background: linear-gradient(
    135deg,
    var(--primary-color),
    white,
    var(--primary-color)
  );
  background-size: 200% 200%;
  animation: gradientAnimation var(--gradient-animation-duration) linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-header {
  background: linear-gradient(
    135deg,
    var(--secondary-color),
    lightblue,
    var(--secondary-color)
  );
  background-size: 200% 200%;
  animation: gradientAnimation var(--gradient-animation-duration) linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 600px) {
  .App {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .App {
    font-size: 0.6rem;
  }
}

